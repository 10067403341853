import React, { useEffect, useState } from 'react';
import Services1 from '../content/images/services.png';
import Logo from '../content/images/redpark.png';
import Background from '../content/images/bg-1.jpg';
import Extras from './website/components/extras'

export default function Services({texts}:any) {

    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className=''>
            <div className='w-full min-h-[50vh] bg-white flex flex-col justify-between items-center py-[3rem]'>
                <img src={Logo} alt="logo" className='w-[10rem] quatro:w-[12rem] seis:w-[17rem]' />
                <h1 className='text-[3rem] text-[var(--primary)] font-bold'>{lang === "pt" ? "Serviços" : "Services"}</h1>
            </div>
            <div className='relative py-[5rem] w-full min-h-screen flex oito:flex-row flex-col gap-[5rem] px-[8vw] bg-black'>
                <div className='flex flex-col w-full gap-6'>
                    <h3 className='text-left font-bold text-[2rem] text-[var(--primary)]'>Valet Parking</h3>
                    <div className='flex flex-col gap-4 p-4 text-center items-center bg-white border-4 border-[var(--primary)] rounded-[25px]'>
                        <p className='font-bold text-[2rem] '>{texts?.baseValue}€</p>
                        <button className='hover:bg-white border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white bg-[var(--primary)] hover:text-black transition-all duration-300' onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                        <ul className='font-bold gap-2 text-left text-[1.2rem] mt-4'>
                            <li className='font-bold text-[1.5rem] text-left mb-4 text-[var(--primary)]'>{lang === "pt" ? "Inclui:" : "Includes:"}</li>
                            <li>{lang === "pt" ? "Seguro de Transporte" : "Transport insurance"}</li>
                            <li>{lang === "pt" ? "Garantia de devolução" : "Garantia de devolução"}</li>
                            <li>{lang === "pt" ? "Segurança" : "Security"}</li>
                            <li>{lang === "pt" ? "Aconselhamento deserviços Carhealth" : "Aconselhamento deserviços Carhealth"}</li>
                        </ul>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center w-full gap-8'>
                    <img src={Services1} alt="service2" className='w-[20rem]' />
                    <p className='w-full text-[1.5rem] font-bold text-white'>
                        {lang === 'pt' ? 'Deixe seu carro no aeroporto conosco e viaje sem problemas, cuidaremos do resto ;)' : 'Leave your car at the airport with us and fly without any problems, we\'ll take care of the rest ;)'}
                    </p>
                </div>
            </div>
            <div className='relative w-full flex flex-col py-[5rem] gap-[3rem] px-[8vw] bg-center bg-cover' style={{ backgroundImage: `url(${Background})` }}>
                <div className="absolute inset-0 w-full h-full bg-black bg-opacity-70"></div>
                <h3 className='relative text-left font-bold text-[2rem] text-[var(--primary)]'>Parking</h3>
                <div className='relative w-full flex oito:flex-row flex-col gap-[5vw]'>
                    <div className='bg-white rounded-[25px] w-full border-4 border-[var(--primary)] p-8 text-center flex flex-col items-center'>
                        {lang === 'pt' ?
                            <p className='text-black text-[1.5rem] seis:text-[2rem] text-center'>
                                Parque <span className='font-bold border-b-4 border-[var(--primary)]'>Coberto</span>
                            </p>
                            :
                            <p className='text-black text-[1.5rem] seis:text-[2rem] text-center'>
                                <span className='font-bold border-b-4 border-[var(--primary)]'>Covered</span> Park
                            </p>
                        }
                        <p className='font-bold text-[1.5rem] mt-8'><span className='mr-2 text-[.8rem]'>desde</span>{texts?.coveredPark}€/{lang === 'pt' ? 'dia' : 'day'}</p>
                        <button className='bg-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white mt-8 border-4 border-[transparent] hover:border-[var(--primary)] hover:bg-white hover:text-black transition-all duration-300' onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                    </div>
                    <div className='bg-white rounded-[25px] w-full border-4 border-[var(--primary)] p-8 text-center flex flex-col items-center'>
                        {lang === 'pt' ?
                            <p className='text-black text-[1.5rem] seis:text-[2rem] text-center'>
                                Parque <span className='font-bold border-b-4 border-[var(--primary)]'>Descoberto</span>
                            </p>
                            :
                            <p className='text-black text-[1.5rem] seis:text-[2rem] text-center'>
                                <span className='font-bold border-b-4 border-[var(--primary)]'>Uncovered</span> Park
                            </p>
                        }
                        <p className='font-bold text-[1.5rem] mt-8'><span className='mr-2 text-[.8rem]'>desde</span>{texts?.uncoveredPark}€/{lang === 'pt' ? 'dia' : 'day'}</p>
                        <button className='bg-white border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-black mt-8 hover:bg-[var(--primary)] hover:text-white transition-all duration-300' onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                    </div>
                </div>
            </div>
            <div className='bg-black py-[3rem]'>
                <Extras texts={texts} />
            </div>
        </div>
    );
}

