import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Website from './pages/website/website';
import Terms from './pages/terms';
import Services from './pages/services';
import About from './pages/about';
import Blog from './pages/blog';
import Article from './pages/article';
import Contact from './pages/contact';
import Navbar from './pages/website/components/navbar';
import Footer from './pages/website/components/footer';
import Book from './pages/book/book';
import Login from './pages/login';
import Admin from './pages/admin';

import NotFoundPage from './pages/notFoundPage';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from './firebase';
import Loading from './components/loading';
import CreateArticle from './pages/createArticle';

function BookLayout({ children }: any) {
	return (
		<>
			{children}
			<div className='relative w-full'>
				<p className="text-[1.2rem] my-10 text-center">
					Powered by{" "}
					<a href="https://visiond.pt" className="font-bold hover:underline">
						Vision D
					</a>
				</p>
				<p onClick={() => window.location.href = "/terms"} className='text-center cursor-pointer hover:underline'>Terms & Conditions</p>
				<p className='w-full text-center'>© All rights reserved. Sky Park 2023 </p>
			</div>
		</>
	)
}

function FooterLayout({ children }: any) {
	return (
		<>
			<Navbar />
			<div className='pt-[4rem]'>
				{children}
				<Footer />
			</div>
		</>
	)
}

function App() {

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLogged, setIsLogged] = useState<boolean>(false);

	const [prices, setPrices] = useState<any>(null);
	const [textsAbout, setTextsAbout] = useState<any>(null);
	const [textsTerms, setTextsTerms] = useState<any>(null);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
			setIsLoading(true);
			if (!user) {
				setIsLogged(false);
				setIsLoading(false);
				return;
			}
			// Check if user is admin
			const usersRef = collection(db, "users");
			const q = query(usersRef, where("email", "==", auth?.currentUser?.email?.split('@')[0]));
			const querySnapshot = await getDocs(q);
			if (querySnapshot.empty) return null;
			const userDoc = querySnapshot.docs[0];
			if (userDoc.data().type === 'Admin') {
				setIsLogged(true);
			}
			setIsLoading(false);
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		async function fetchTexts() {
			setIsLoading(true);
			try {
				let docRef = doc(db, 'redpark', 'prices');
				let docSnap = await getDoc(docRef);
				if (docSnap.exists()) setPrices(docSnap.data());

				// Fetch about and terms texts
				docRef = doc(db, 'redpark', 'about');
				docSnap = await getDoc(docRef);
				if (docSnap.exists()) setTextsAbout(docSnap.data());
				docRef = doc(db, 'redpark', 'terms');
				docSnap = await getDoc(docRef);
				if (docSnap.exists()) setTextsTerms(docSnap.data());
			} catch (error) {
				console.error("Error getting document: ", error);
			}
			setIsLoading(false);
		}
		fetchTexts();
	}, []);

	if (isLoading) {
		return (
			<Loading />
		)
	}

	return (
		<div id='app' className='relative'>
			<BrowserRouter>
				<Routes>
					<Route index element={<FooterLayout><Website texts={prices} /></FooterLayout>} />
					<Route path="/about" element={<FooterLayout><About texts={textsAbout} /></FooterLayout>} />
					<Route path="/services" element={<FooterLayout><Services texts={prices} /></FooterLayout>} />
					<Route path="/blog" element={<FooterLayout><Blog /></FooterLayout>} />
					<Route path="/article/:id" element={<FooterLayout><Article /></FooterLayout>} />
					<Route path="/contact" element={<FooterLayout><Contact /></FooterLayout>} />
					<Route path="/terms" element={<FooterLayout><Terms texts={textsTerms} /></FooterLayout>} />
					<Route path="/login" element={<FooterLayout><Login /></FooterLayout>} />
					{isLogged && <Route path="/admin" element={<FooterLayout><Admin /></FooterLayout>} />}
					{isLogged && <Route path="/createArticle" element={<FooterLayout><CreateArticle /></FooterLayout>} />}
					{isLogged && <Route path="/editArticle/:id" element={<FooterLayout><CreateArticle /></FooterLayout>} />}
					<Route path="*" element={<BookLayout><NotFoundPage /></BookLayout>} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
