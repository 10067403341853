import React, { useEffect, useState } from 'react';

import LightIcon from '../../../content/icons/light.png';
import WashIcon from '../../../content/icons/wash.png';
import AnaliseIcon from '../../../content/icons/analise.png';
import CareIcon from '../../../content/icons/care.png';


export default function Extras({ texts }: any) {
    const [lang, setLang] = useState("pt");

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    function ExtraService({ ...props }) {
        return (
            <div className='w-full flex nove:flex-row flex-col gap-[2rem] justify-between items-center'>
                <div className='flex flex-col items-center gap-8 nove:flex-row nove:items-start'>
                    <div className='shrink-0 w-[10rem] h-[10rem] rounded-[35px] bg-[var(--primary)]'>
                        <img src={props.src} alt="icon" className='w-full h-full' />
                    </div>
                    <div>
                        <h4 className='text-[1.5rem] mil:text-[2rem] font-bold'>{props.title}</h4>
                        <p className='text-[1.2rem] font-poppinsM'>{props.text}</p>
                    </div>
                </div>
                <div className='flex flex-col items-center h-full gap-4'>
                    <p className='font-bold text-[2rem]'>{props.price}€</p>
                    <button className=' border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white hover:bg-white hover:text-black transition-all duration-300' onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")}>{lang === 'pt' ? 'RESERVAR' : 'BOOK'}</button>
                </div>
            </div>
        )
    }

    return (
        <div className='relative min-h-screen bg-black px-[8vw] pb-[5rem]'>
            <h3 className='text-[2rem] text-[var(--primary)] font-bold mb-[5rem]'>Extras</h3>
            <div className='flex flex-col w-full gap-[7rem] text-white'>
                <ExtraService
                    src={LightIcon}
                    title={lang === 'pt' ? 'Carros elétricos' : 'Electric Cars'}
                    text={lang === 'pt' ? 'Carregamento para todo tipo de veículos elétricos no aeroporto de faro. O carregamento mais perto e seguro (Carregamento até 22 KW) do terminal das partidas do aeroporto de faro.' : 'Charging for all types of electric vehicles at Faro airport. The closest and safest charging (Charging up to 22 KW) to the Faro airport departures terminal.'}
                    price={texts?.eletric}
                />
                <ExtraService
                    src={WashIcon}
                    title={lang === 'pt' ? 'Lavagem' : 'Wash'}
                    text={lang === 'pt' ? 'Lavagem Manual junto ao aeroporto de faro. Aspiraçao a 5 minutos do aeroporto. Lavagem completa emquanto viaja do terminal das partida no aeroporto de faro.' : 'Manual washing near Faro airport. Aspiration 5 minutes from the airport. Complete washing while traveling from the departure terminal at Faro airport.'}
                    price={texts?.wash}
                />
                <ExtraService
                    src={AnaliseIcon}
                    title={lang === 'pt' ? 'Inspeção' : 'Condition'}
                    text={lang === 'pt' ? 'Fazemos a sua inspeção junto do aeroporto de faro, enquanto viaja nós inspecionamos o seu carro, quando voltar ao aeroporto em faro o seu veículo estará pronto.' : 'We do your inspection near Faro airport, while you travel we inspect your car, when you return to Faro airport your vehicle will be ready.'}
                    price={texts?.condition}
                />
                <ExtraService
                    src={CareIcon}
                    title={lang === 'pt' ? 'CarHealth - Análise do carro' : 'CarHealth - Analyze the car'}
                    text={lang === 'pt' ? 'Analisamos o carro e vemos quais são as suas necessidades, entramos em contato com você e aconselhamos sobre o serviço ideal.' : 'We analyze the car and see what your needs are, contact you and advise you on the ideal service.'}
                    price={texts?.carHealth}
                />
                {/* <div className='flex justify-center w-full'>
                    <button className=' border-4 border-[var(--primary)] rounded-[10px] w-[15rem] py-4 font-bold text-white hover:bg-white hover:text-black transition-all duration-300' onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")}>{lang === 'pt' ? 'EXPLORAR MAIS...' : 'EXPLORE MORE...'}</button>
                </div> */}
            </div>
        </div>
    );
}

