import React, { useEffect, useState } from 'react';

// import Logo from '../../../content/images/redpark.png';
import Image1 from '../../../content/images/header1.png';
import Background from '../../../content/images/bg-1.jpg';

export default function Header() {

    const [lang, setLang] = useState("pt");

    const changeLanguage = (lang: string) => {
        localStorage.setItem("lang", lang);
        setLang(lang);
        window.location.reload();
    };

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    return (
        <div className='bg-[rgba(0,0,0,.5)] w-full min-h-screen px-[8vw] pt-[2rem] text-white bg-center bg-cover' style={{ backgroundImage: `url(${Background})` }}>
            <div className="absolute inset-0 w-full h-full bg-black bg-opacity-70"></div>
            <div className='relative flex items-center justify-between w-full'>
                <p className='text-[var(--primary)] text-[2.5rem]'>Red Park - Faro</p>
                <p className='font-bold'><span className={`cursor-pointer ${lang === 'pt' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('pt')}>PT</span>&ensp;&ensp;|&ensp;&ensp;<span className={`cursor-pointer ${lang === 'en' ? 'text-[var(--primary)]' : ''}`} onClick={() => changeLanguage('en')}>EN</span></p>
            </div>
            <div className='relative flex justify-between mt-[5rem]'>
                <div className='flex flex-col w-full gap-10'>
                <h1 className='text-[2rem] seis:text-[2.5rem] font-bold'>{lang === "pt" ? "Estacione & Voe" : "Park & Fly"}</h1>
                    <h2 className='text-balance text-[1.5rem] quatro:text-[2rem] seis:text-[2.5rem] font-poppinsM'>
                        {lang === "pt" ? "Deixe o seu carro connosco e viaje em tranquilidade" : "Leave the car with us and travel in peace"}
                    </h2>
                    <div className='grid w-full gap-4 quatro:grid-cols-2'>
                        <button onClick={() => window.open("https://multipark.pt/book?city=faro&parkBrand=redpark")} className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300'>{lang === 'pt' ? 'RESERVAR':'BOOK'}</button>
                        <button onClick={() => window.open("https://redpark.pt", "_blank")} className='bg-[var(--primary)] rounded-[10px] w-full py-4 font-bold hover:brightness-90 transition-all duration-300'>{lang === 'pt' ? 'RedPark Lisboa':'RedPark Lisbon'}</button>
                    </div>
                </div>
                <div className='items-end justify-center hidden w-full mil:flex'>
                    <img src={Image1} alt="header1" className='w-[25rem] brightness-0 invert' />
                </div>
            </div>
        </div>
    );
}

